import React, { useState } from 'react';
import * as d3 from "d3";
import styles from "../index.css";


function HeatmapComponent({ x, y, width, height, fill, onMouseEnter, onMouseLeave, shape, name }) {

  const renderShape = () => {
    if (shape === "circle") {
      const radius = Math.min(width, height) / 2;
      const cx = x + radius;
      const cy = y + radius;

      return (
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          opacity={1}
          stroke="#000000"
          fill={fill}
          fillOpacity={1}
          strokeWidth={2}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      );
    } else if (shape === "rect") {
      return (
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          rx="15"
          opacity={1}
          stroke="#000000"
          fill={fill}
          fillOpacity={1}
          strokeWidth={2}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      );
    }
  };

  return (
    <svg>
      {renderShape()}
      
      <text
        x={shape === "circle" ? x + Math.min(width, height) / 2 : x + width/2}
        y={shape === "circle" ? y + Math.min(width, height) / 2 : y + height/2}
        fontSize="15"
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {name}
      </text>
    </svg>
  );
}

export default HeatmapComponent;

// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartRegisterScreen from './screens/StartRegister';
import RegisterScreen from './screens/Register';
import LoginScreen from './screens/Login';
import HomeScreen from './screens/Home';
import MapSalesScreen from './screens/MapSales';
import ImportDataScreen from './screens/ImportData';
import ImportDataEditScreen from './screens/ImportDataEdit';
import './index.css';

const App = () => {
  return (
    <Router>
      <div className="whole-screen">
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/home" element={<HomeScreen />} />
          <Route exact path="/login" element={<LoginScreen />} />
          <Route exact path="/start-register" element={<StartRegisterScreen />} />
          <Route exact path="/register" element={<RegisterScreen />} />
          <Route path="/map-sales" element={<MapSalesScreen />} />
          <Route path="/import-data" element={<ImportDataScreen />} />
          <Route path="/edit-data" element={<ImportDataEditScreen />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import "react-widgets/styles.css";
import "./import.css";
import openDataFile from "./components/fileImport.js";
import { Scrollbar } from "react-scrollbars-custom";

import columnKeys from "../constants/columns.js";

import config from "../getConfig.js";

import global from "../global/globalStore.js";
import styles from "./Dropbox.module.css";
import { ReactComponent as UploadIconSVG } from '../images/upload-icon.svg';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import visibilityIcon from '../images/visibility.svg';
import hiddenIcon from '../images/hidden.svg';
import Modal from 'react-bootstrap/Modal';
import { ArrowLeftSquare } from 'react-bootstrap-icons';

function ImportData() {
    // read config file to get client and server address

    const fileInputRef = useRef(null);
    const UploadIcon = () => {
        return <UploadIconSVG className={styles.uploadIcon} />;
    }

    const [isTableVisible, setIsTableVisible] = useState(false);

    const toggleTableVisibility = () => {
        setIsPreviewVisible(!isPreviewVisible);
        setIsSub(!isSub);
    };

    const Eye = () => {
        return <Eye className={styles.visibility} />;
    }
    const handleDivClick = () => {
        fileInputRef.current.click();
    };


    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("");
    const [data, setData] = useState([]);

    const [isHeaderData, setIsHeaderData] = useState(false);

    const [draggedItem, setDraggedItem] = useState(null);
    const [droppedItems, setDroppedItems] = useState({});
    const [lineQty, setLineQty] = useState(1);

    const [mappingObjectList, setMappingObjectList] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();


    const colourOriginal = [
        "blue",
        "yellow",
        "red",
        "green",
        "teal",
        "orange",
        "purple",
        "pink",
        "brown",
        "grey",
        "cyan",
        "lightgreen",
    ];

    const [colour, setColour] = useState(colourOriginal);

    // Number of items to display per page
    const itemsPerPage = 10;

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState([]);

    const itemsPerPageImport = 10;

    const [currentPageImport, setCurrentPageImport] = useState(1);

    const [isDropboxVisible, setIsDropboxVisible] = useState(true);

    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    let [isMatchingVisible, setIsMatchingVisible] = useState(false);

    const [isMatchingTableVisible, setIsMatchingTableVisible] = useState(false);

    const [isSub, setIsSub] = useState(true);

    // const handleCheckboxChange = () => {
    //   setIsHeaderData(!isHeaderData);
    // };

    const [successfulUploads, setSuccessfulUploads] = useState(0);
    const [uploadFinish, setUploadFinish] = useState(false);

    const [sourceColorMap, setSourceColorMap] = useState({});
    const [destColorMap, setDestColorMap] = useState({});


    const handleOnClick = (event) => {
        setData([]);
        setErrorMessage("");
        setInfoMessage("");
        setLoadingMessage("");
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        openDataFile(file)
            .then((returnData) => {
                // Handle the returnData here
                if (returnData) {
                    if (returnData.errorMessage) {
                        setErrorMessage(returnData.errorMessage);
                    } else {
                        setData(returnData.data);

                        displayData(returnData.data);

                        clearTable();
                        //navigate('/import-data-second');
                        setIsDropboxVisible(false);
                        setIsPreviewVisible(true);
                    }
                }
            })
            .catch((error) => {
                console.error("Error from openDataFile:", error);
                setErrorMessage("Error from openDataFile:", error);
            });
    };


    function handleUpload(row) {
        console.log(row);
        setErrorMessage("");
        setLoadingMessage("Loading, please wait...");
        // if row is not null, it means we want to upload only one row
        if (row) {
            // when uploadData is complete, change loading Messge to null
            if (!droppedItems[row]) {
                setErrorMessage("No data to upload");
                setLoadingMessage("");
                return null;
            }

            if (checkPrice(droppedItems[row]) === false) {
                return null;
            }

            if (checkData(droppedItems[row]) === false) {
                // return null;
            }

            UploadData(droppedItems[row]);
        }
        else {
            // if row is null, it means we want to upload all rows
            for (const item in droppedItems) {
                if (droppedItems.hasOwnProperty(item)) {
                    if (checkPrice(droppedItems[item]) === false) {
                        return null;
                    }
                    if (checkData(droppedItems[item]) === false) {
                        // return null;
                    }
                }
            }

            // Iterate through the object
            setLoadingMessage("Loading, please wait...");
            UploadDataAll();
        }
    }

    function checkPrice(line) {
        let price = line.Price;

        // check if price is number
        if (price) {
            if (isNaN(price)) {
                var page = Math.floor(line.numLine / itemsPerPage) + 1;

                setErrorMessage(
                    "Error on line " +
                    line.numLine +
                    " (page " +
                    page +
                    ") : Price is not a number"
                );
                setLoadingMessage("");
                return false;
            }
        }
        return true;
    }

    function checkData(line) {
        // check if at least one field is not empty
        if (
            !line.Name &&
            !line.Description &&
            !line.Barcode &&
            !line.Price &&
            !line.Discounts
        ) {
            setErrorMessage("Error on line " + line.numLine + " : No data to upload");
            setLoadingMessage("");
            return false;
        }

        return true;
    }

    function UploadData(row) {
        setUploadFinish(false);
        if (!row) {
            setErrorMessage("No data to upload");
            setLoadingMessage("");
            return null;
        }
        setErrorMessage("");
        setInfoMessage("");

        let addressCreateImport =
            config.serverUrl + "/createImport";

        console.log("global.customerId", global.customerId);

        fetch(addressCreateImport, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                items: row,
                customerId: 9999,
            }),

        })
            .then((response) => {
                if (response.status === 200) {
                    setInfoMessage("Import successful.");
                    setLoadingMessage("");
                    setUploadFinish(true);
                    setSuccessfulUploads(1);
                } else {
                    console.log("response", response);
                    return response.json();
                }
            })
            .then((data) => {
                if (data && data.errorMessage) {
                    console.log("data.errorMessage", data.errorMessage);

                    setErrorMessage(
                        "Import error on line  " + row.numLine + " : " + data.errorMessage
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);

                setErrorMessage(
                    "Import error on line  " + row.numLine + " : " + error.message
                );
                setLoadingMessage("");
            });
        setShowModal(true);
    }

    function UploadDataAll() {
        setUploadFinish(false);
        if (!droppedItems) {
            setErrorMessage("No data to upload");
            setLoadingMessage("");
            return null;
        }
        setErrorMessage("");
        setInfoMessage("");

        const batchSize = 500; // Define the batch size
        let total = 0;

        // Convert the object values into an array
        const itemsArray = Object.values(droppedItems);

        // Split the itemsArray into batches
        const batches = [];
        for (let i = 0; i < itemsArray.length; i += batchSize) {
            const batch = itemsArray.slice(i, i + batchSize);
            batches.push(batch);
        }
        // console.log(JSON.stringify(batches.length));

        // Send batches of data sequentially
        let currentBatchIndex = 0;
        let err = false;

        const sendBatch = () => {
            if (currentBatchIndex >= batches.length) {
                // All batches have been sent
                setInfoMessage("Import successful.");
                setLoadingMessage("");
                return;
            }

            const currentBatch = batches[currentBatchIndex];
            const droppedItemsJson = JSON.stringify({ droppedItems: currentBatch });

            let addressCreateImportAll =
                config.serverUrl + "/createImportAll";

            console.log("global.customerId", global.customerId);

            fetch(addressCreateImportAll, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    items: droppedItemsJson,
                    customerId: global.customerId,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        // Batch import successful, proceed to the next batch
                        currentBatchIndex++;
                        total += currentBatch.length;
                        sendBatch();
                    } else {
                        console.log("response", response);
                        err = true;
                        return response.json();
                    }
                })
                .then((data) => {
                    if (data && data.errorMessage) {
                        console.log("data.errorMessage", data.errorMessage);
                        setErrorMessage("Import error: " + data.errorMessage);
                        setLoadingMessage("");
                        err = true;
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    setErrorMessage("Import error: " + error.message);
                    setLoadingMessage("");
                    err = true;
                })
                .finally(() => {
                    setUploadFinish(true);
                    setSuccessfulUploads(total);
                });
        };

        sendBatch(); // Start sending the batches

        if (!err) {
            // filename remove path, only keep the file name

            let filename = document.getElementById("file").value;
            const lastSlash = filename.lastIndexOf("/");
            const lastBackSlash = filename.lastIndexOf("\\");
            let lastSlashIndex = 0;

            if (lastSlash > lastBackSlash) {
                lastSlashIndex = lastSlash;
            } else {
                lastSlashIndex = lastBackSlash;
            }

            filename = filename.slice(lastSlashIndex + 1);

            const objParam = {
                mapping: mappingObjectList,
                filename: filename,
                customerId: global.customerId,
            };

            let addressCreateMapping =
                config.serverUrl + "/createMapping";

            fetch(addressCreateMapping, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(objParam), // Convert objParam to JSON string
            })
                .then((response) => {
                    if (response.ok) {
                        // Import Mongo Successful
                    } else {
                        console.log("response", response);
                        err = true;
                        return response.json();
                    }
                })
                .then((data) => {
                    if (data && data.errorMessage) {
                        console.log("data.errorMessage", data.errorMessage);
                        setErrorMessage("Import error: " + data.errorMessage);
                        setLoadingMessage("");
                        err = true;
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    setErrorMessage("Import error: " + error.message);
                    setLoadingMessage("");
                    err = true;
                });
        }

        setInfoMessage("Import successful.");
        setLoadingMessage("");
        setShowModal(true);
    }

    const handleBatchSuccess = (batchSize) => {
        setSuccessfulUploads(prev => prev + batchSize);
    }


    const handleCellChange = (value, columnKey, i) => {
        let newDroppedItems = { ...droppedItems };

        i += (currentPageImport - 1) * itemsPerPageImport;

        // Look for the i element of newDroppedItems, then put the new value in it
        console.log("handleCellChange");
        for (const item in newDroppedItems) {
            if (newDroppedItems.hasOwnProperty(item)) {
                if (item === i.toString()) {
                    newDroppedItems[item][columnKey] = value;
                    console.log("!!!!!" + value);
                    break;
                }
            }
        }

        setDroppedItems(newDroppedItems);
    };

    const handleDragStart = (event, item, fullColumn) => {
        item = {
            name: item,
            fullColumn: fullColumn ? true : false,
            backgroundColor: event.target.style.backgroundColor,
        };

        setDraggedItem(item);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, coordx, coordy, fullColumn) => {
        setErrorMessage("");
        event.preventDefault();

        if (!draggedItem) {
            return;
        }

        if (!draggedItem.fullColumn) {
            if (fullColumn) {
                console.log("You can only drop a full column on a column header");
                setErrorMessage("You can only drop a full column on a column header");
                return null;
            }

            if (!droppedItems[coordy]) {
                setDroppedItems((prevItems) => ({
                    ...prevItems,
                    [coordy]: { numLine: coordy, [coordx]: draggedItem.name },
                }));
            } else {
                setDroppedItems((prevItems) => ({
                    ...prevItems,
                    [coordy]: { ...prevItems[coordy], [coordx]: draggedItem.name },
                }));
            }
        } else {
            if (!fullColumn) {
                console.log("You can only drop a full column on a column header");
                setErrorMessage("You can only drop a full column on a column header");
                return null;
            }

            let columnData = [];
            let columnPosition = data[0].indexOf(draggedItem.name);

            for (let i = 0; i < data.length; i++) {
                columnData.push(data[i][columnPosition]);
            }

            if (!isHeaderData) {
                columnData = columnData.slice(1);
            }

            // Find how much data to insert, create new lines if needed, maximum <itemsPerPageImport> lines per page
            if (columnData.length > lineQty) {
                if (columnData.length > itemsPerPageImport) {
                    setLineQty(itemsPerPageImport);
                } else {
                    setLineQty(columnData.length);
                }
            }

            const tempDroppedItems = {};

            for (let i = 0; i < columnData.length; i++) {
                tempDroppedItems[i + 1] = {
                    numLine: i + 1,
                    ...droppedItems[i + 1],
                    [coordx]: columnData[i],
                };
            }

            // for each object in tempDroppedItems, check its price property, if it's not a number, set it to 0 instead
            for (const item in tempDroppedItems) {
                if (tempDroppedItems.hasOwnProperty(item)) {
                    if (checkPrice(tempDroppedItems[item]) === false) {
                        tempDroppedItems[item].Price = 0;
                    }
                }
            }

            setDroppedItems((prevItems) => ({ ...prevItems, ...tempDroppedItems }));

            const gridItems = document.querySelectorAll(".grid-item-column-source");

            let dropSource = null;

            gridItems.forEach((item) => {
                if (item.textContent === draggedItem.name.toString()) {
                    dropSource = item;
                }
            });

            // randomly select a colour among the following ones
            // blue, yellow, red, green, teal, orange, purple, pink, brown, grey

            let randomColour = colour[Math.floor(Math.random() * colour.length)];

            let colourTemp = colour;

            // when colour is picked, remove it from the array
            colourTemp = colourTemp.filter((item) => item !== randomColour);

            let mappingObjectListTemp = mappingObjectList;

            if (
                event.target.style.backgroundColor &&
                event.target.style.backgroundColor !== "" &&
                event.target.style.backgroundColor !== "white"
            ) {
                colourTemp.push(event.target.style.backgroundColor);
            }

            if (draggedItem.backgroundColor &&
                draggedItem.backgroundColor !== "" &&
                draggedItem.backgroundColor !== "white"
            ) {
                colourTemp.push(draggedItem.backgroundColor);
            }

            const eventTargetDbName = columnKeys.find(
                (key) => key.displayName === event.target.textContent
            ).dbName;

            // if event.target.textContent is in destination of mappingObjectList, remove it
            for (const item in mappingObjectListTemp) {
                if (mappingObjectListTemp.hasOwnProperty(item)) {
                    if (mappingObjectListTemp[item].destination === eventTargetDbName) {
                        mappingObjectListTemp.splice(item, 1);
                        break;
                    }
                }
            }

            //  if draggedItem.name is in source of mappingObjectList, remove it
            for (const item in mappingObjectListTemp) {
                if (mappingObjectListTemp.hasOwnProperty(item)) {
                    if (mappingObjectListTemp[item].source === draggedItem.name) {
                        mappingObjectListTemp.splice(item, 1);
                        break;
                    }
                }
            }

            let mappingObject = {
                source: draggedItem.name,
                destination: eventTargetDbName,
            };

            // check if similar record in mappingObjectList already exists, if yes, do not add it
            let exists = false;
            for (const item in mappingObjectList) {
                if (mappingObjectList.hasOwnProperty(item)) {
                    if (
                        mappingObjectList[item].source === mappingObject.source &&
                        mappingObjectList[item].destination === mappingObject.destination
                    ) {
                        exists = true;
                        break;
                    }
                }
            }

            if (!exists) {
                mappingObjectListTemp.push(mappingObject);
            }

            setMappingObjectList(mappingObjectListTemp);

            setSourceColorMap({ ...sourceColorMap, [dropSource.innerHTML]: randomColour });
            setDestColorMap({ ...destColorMap, [event.target.innerHTML]: randomColour });

            // check if there are backgroundColour in gridItemsSrc that are not in gridItemsDest and remove them

            const gridItemsDest = document.querySelectorAll(
                ".grid-item-column-destination"
            );

            const gridItemsSrc = document.querySelectorAll(
                ".grid-item-column-source"
            );

            // Convert gridItemsSrc and gridItemsDest to arrays
            const srcItemsArray = Array.from(gridItemsSrc);
            const destItemsArray = Array.from(gridItemsDest);

            // Convert background colors to Sets
            const sourceColors = new Set(
                srcItemsArray.map((item) => item.style.backgroundColor)
            );
            const destinationColors = new Set(
                destItemsArray.map((item) => item.style.backgroundColor)
            );

            setColour(colourTemp);
        }
    };

    function addNewLine() {
        let item = {
            numLine: lineQty + 1,
        };

        setDroppedItems((prevItems) => ({
            ...prevItems,
            [lineQty + 1]: item,
        }));

        setLineQty((prevLineQty) => prevLineQty + 1);
    }

    function clearTable() {
        let emptyDroppedItems = {};
        setDroppedItems(emptyDroppedItems);
        setLineQty(0);
        setCurrentPageImport(1);
        setMappingObjectList([]);
    }

    function deleteCell(cellItem, column, line) {
        // deleteCell(cellItem, columnKey.dbName, i + 1)}
        console.log('Before');
        console.log("cellItem:", cellItem);
        console.log("column:", column);
        console.log("line:", line);

        let newDroppedItems = { ...droppedItems };

        // Look for the cellItem in newDroppedItems, then put it to null

        line = line + (currentPageImport - 1) * itemsPerPageImport;

        for (const item in newDroppedItems) {
            if (newDroppedItems.hasOwnProperty(item)) {
                for (const key in newDroppedItems[item]) {
                    if (newDroppedItems[item].hasOwnProperty(key)) {
                        let sLine = line.toString();

                        if (
                            newDroppedItems[item][key] === cellItem &&
                            item === sLine &&
                            key === column
                        ) {
                            newDroppedItems[item][key] = null;
                            break;
                        }
                    }
                }
            }
        }
        setDroppedItems(newDroppedItems);
        console.log('After');
        console.log("cellItem:", cellItem);
        console.log("column:", column);
        console.log("line:", line);
    }

    function deleteColumn(column) {
        console.log('!!!' + column);
        function setBackgroundColor(items, color) {
            items.forEach((item) => {
                item.style.backgroundColor = color;
            });
            console.log('Button deleteAll clicked');
        }

        function resetState() {
            newDroppedItems = {};
            setLineQty(0);
            setCurrentPageImport(1);
            setBackgroundColor(
                document.querySelectorAll(".grid-item-column-source"),
                ""
            );
            setBackgroundColor(
                document.querySelectorAll(".grid-item-column-destination"),
                ""
            );
            setColour(colourOriginal);

            document.querySelectorAll(".grid-item-column-source").forEach((item) => {
                item.style.color = "black";
            });

            document.querySelectorAll(".grid-item-column-destination").forEach((item) => {
                item.style.color = "black";
            });
        }

        let newDroppedItems = { ...droppedItems };

        if (column) {
            // Look for the column in newDroppedItems, then put it to null
            for (const item in newDroppedItems) {
                if (newDroppedItems.hasOwnProperty(item)) {
                    columnKeys.forEach((col) => {
                        newDroppedItems[item][col.dbName] =
                            column === col.dbName ? null : newDroppedItems[item][col.dbName];
                    });
                }
            }

            // check colour of column header, if it's not empty, put it back in colour array
            const gridItems = document.querySelectorAll(
                ".grid-item-column-destination"
            );

            // get description that matches column
            const descColumn = columnKeys.find(
                (key) => key.dbName === column
            ).displayName;

            gridItems.forEach((item) => {
                if (item.textContent === descColumn) {
                    if (item.style.backgroundColor && item.style.backgroundColor !== "") {
                        let colourTemp = colour;
                        colourTemp.push(item.style.backgroundColor);
                        setColour(colourTemp);

                        // Remove colour from source that has same colour as destination
                        const gridItemsSource = document.querySelectorAll(
                            ".grid-item-column-source"
                        );

                        gridItemsSource.forEach((itemSrc) => {
                            if (
                                itemSrc.style.backgroundColor === item.style.backgroundColor
                            ) {
                                itemSrc.style.backgroundColor = "";
                            }
                        });

                        item.style.backgroundColor = "";

                        // also remove it from mappingObjectList if present

                        let mappingObjectListTemp = mappingObjectList;

                        for (const item in mappingObjectListTemp) {
                            if (mappingObjectListTemp.hasOwnProperty(item)) {
                                console.log(
                                    "mappingObjectListTemp[item].destination",
                                    mappingObjectListTemp[item].destination
                                );

                                if (mappingObjectListTemp[item].destination === column) {
                                    mappingObjectListTemp.splice(item, 1);
                                    break;
                                }
                            }
                        }

                        setMappingObjectList(mappingObjectListTemp);
                    }
                }
            });

            // count each object in newDroppedItems, check all its properties. If they're all null or undefined, it doesn't count
            let count = 0;

            for (const item in newDroppedItems) {
                if (newDroppedItems.hasOwnProperty(item)) {
                    for (const key in newDroppedItems[item]) {
                        let obj = newDroppedItems[item];

                        // check all properties except numLine

                        if (key !== "numLine") {
                            if (obj[key] === null || obj[key] === undefined) {
                            } else {
                                count++;
                                break;
                            }
                        }
                    }
                }
                if (count > 0) {
                    break;
                }
            }

            // If no item left in newDroppedItems, set lineQty to 0 and currentPageImport to 1, reset colours
            if (count === 0) {
                resetState();
            }
        } else {
            // empty newDroppedItems, set lineQty to 0 and currentPageImport to 1, reset colours

            resetState();
        }

        setDroppedItems(newDroppedItems);
        setSourceColorMap({});
        setDestColorMap({});
    }

    const renderGridItems = () => {
        const startIndex = (currentPageImport - 1) * itemsPerPageImport;
        const endIndex = startIndex + itemsPerPageImport;

        // count how many objects are in droppedItems object
        let count = 0;
        for (const item in droppedItems) {
            if (droppedItems.hasOwnProperty(item)) {
                count++;
            }
        }

        if (count === 0) {
            count = lineQty;
        }

        if (droppedItems && count > 0) {
            // only take the objects from droppedItems from startIndex to endIndex
            let currentPageDataImportTemp = Object.values(droppedItems).slice(
                startIndex,
                endIndex
            );

            // add a dummy item at position 0 to avoid having an empty first line in currentPageDataImportTemp
            currentPageDataImportTemp.unshift({});

            // console.log(JSON.stringify(currentPageDataImportTemp));
            // console.log(lineQty);
            // console.log(JSON.stringify(columnKeys));

            const gridItems = [];

            for (let i = 0; i < lineQty; i++) {
                const cells = columnKeys.map((columnKey) => {
                    const droppedItem =
                        currentPageDataImportTemp && currentPageDataImportTemp[`${i + 1}`];
                    let cellItem = droppedItem && droppedItem[columnKey.dbName];
                    if (!cellItem && cellItem !== 0) {
                        cellItem = '';
                    }
                    // console.log(JSON.stringify(cellItem));

                    return (
                        <td
                            key={`${columnKey.dbName}${i + 1}`}
                            // className="grid-item"
                            onDragOver={handleDragOver}
                            onDrop={(event) =>
                                handleDrop(event, columnKey.dbName, `${i + 1}`, false)
                            }
                        >
                            {/*{<div>`{JSON.stringify(cellItem)}`</div>}*/}
                            <div style={
                                {
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto',
                                    alignItems: 'center'
                                }
                            }>
                                <input
                                    // className="grid-item-edit"
                                    type="text"
                                    id={`${columnKey.dbName}${i + 1}`}
                                    value={cellItem}
                                    style={{ width: '120px' }}
                                    onChange={(event) =>
                                        handleCellChange(event.target.value, columnKey.dbName, i + 1)
                                    }
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-trash" viewBox="0 0 16 16"
                                    onClick={() => handleCellChange('', columnKey.dbName, i + 1)}
                                >
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                    <path
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                </svg>
                            </div>
                        </td>

                    );
                });

                gridItems.push(
                    <React.Fragment key={i}>
                        <tr>
                            {cells}
                            <td>
                                <button className="btn btn-info"
                                    style={{
                                        width: 100,
                                        fontSize: '8px'
                                    }}
                                    onClick={() => handleUpload(i + 1 + startIndex)}>Upload
                                </button>
                            </td>
                        </tr>
                    </React.Fragment>
                );
                { <div>JSON.stringify(`${gridItems}`)</div> }
            }
            return gridItems;
        }
        return null;
    };

    useEffect(() => {
        displayData(data);
    }, [currentPage]);

    useEffect(() => {
    }, [currentPageData]);

    // Display the data for the current page
    const displayData = (dataParam) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        if (dataParam) {
            const currentPageData = dataParam.slice(startIndex, endIndex);

            setCurrentPageData(currentPageData);
        }
    };

    // Go to the previous page
    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Go to the next page
    const goToNextPage = () => {
        const totalPages = Math.ceil(data.length / itemsPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const goToPage = (pageNumber) => {
        const totalPages = Math.ceil(data.length / itemsPerPage);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    // Calculate total pages
    var totalPages = 1;

    if (data && itemsPerPage > 0) {
        totalPages = Math.ceil(data.length / itemsPerPage);
    }

    // Calculate total pages Import
    var totalPagesImport = 1;

    if (droppedItems && itemsPerPageImport > 0) {
        // count how many objects are in droppedItems object
        let count = 0;
        for (const item in droppedItems) {
            if (droppedItems.hasOwnProperty(item)) {
                count++;
            }
        }

        totalPagesImport = Math.ceil(count / itemsPerPageImport);
        if (totalPagesImport === 0) {
            totalPagesImport = 1;
        }
    }

    return (
        <>
            <input
                type="file"
                id="file"
                onChange={handleFileChange}
                onClick={handleOnClick}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            {isDropboxVisible && (
                <div id="dropbox" className={styles.a1Upload}>
                    <div className={styles.uploadFileBackgroundParent}>
                        <div className={styles.uploadFileBackground} />
                        <div className={styles.dragDropFiles}>
                            <b className={styles.dragDropContainer}>
                                <span className={styles.dragDrop}>{`Drag & drop files or`}</span>
                                <span className={styles.span}>{` `}</span>
                                <span onClick={handleDivClick} className={styles.browse}>Browse</span>
                            </b>
                        </div>
                        <div className={styles.supportedFormats}>
                            <div
                                className={styles.supportedFormatesExcel}
                            >{`Supported formates: Excel, csv, txt `}</div>
                        </div>
                        {/*<img className={styles.uploadIcon} alt="" src="../../images/upload-icon.svg" />*/}
                        <UploadIcon />
                        <div className={styles.button} id="clickDiv" onClick={handleDivClick}>
                            <div className={styles.selectFile}>Select file</div>
                        </div>
                    </div>
                </div>
            )}

            {/* display the whole content of data*/}
            {data && data.length > 0 && (
                <>
                    {/*Preview*/}
                    {isPreviewVisible && (
                        <>
                            {isSub && (
                                <ArrowLeftSquare style={{ fontSize: '2.5rem' }} onClick={() => {
                                    setIsPreviewVisible(false);
                                    navigate('/import-data');
                                    window.location.reload();
                                }}
                                />
                            )}
                            <br />
                            <br />
                            <div id="preview" className="row d-flex justify-content-center">
                                <Scrollbar style={{ width: 1500, height: 500 }}>
                                    {/*table*/}
                                    <table className="table table-bordered table-hover">
                                        {!isHeaderData ? (
                                            <thead>
                                                <tr className="table-secondary ">
                                                    {data[0].map((cell) => (
                                                        <th scope="col">
                                                            <div
                                                            // className="grid-item"
                                                            // draggable
                                                            // onDragStart={(event) =>
                                                            //     handleDragStart(event, cell, true)
                                                            // }
                                                            >
                                                                {cell}
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                        ) : null}

                                        <tbody>
                                            {!isHeaderData && (currentPage === 1)
                                                ? currentPageData.slice(1).map((row) => (
                                                    <tr>
                                                        {row.map((cell) => (
                                                            <td>
                                                                <div
                                                                // className="drag-item"
                                                                // draggable
                                                                // onDragStart={(event) =>
                                                                //     handleDragStart(event, cell, false)
                                                                // }
                                                                >
                                                                    {cell}
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))
                                                : currentPageData.map((row) => (
                                                    <tr>
                                                        {row.map((cell) => (
                                                            <td>
                                                                {/*<div*/}
                                                                {/*    className="drag-item"*/}
                                                                {/*    draggable*/}
                                                                {/*    onDragStart={(event) =>*/}
                                                                {/*        handleDragStart(event, cell, false)*/}
                                                                {/*    }*/}
                                                                {/*>*/}
                                                                {cell}
                                                                {/*</div>*/}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </Scrollbar>

                                <br />

                                {/*pagination*/}
                                {/*<div className="d-flex justify-content-center">*/}
                                {/*  <br />*/}
                                <form className="row g-2">
                                    <nav aria-label="...">
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={goToPreviousPage}
                                                    disabled={currentPage === 1}
                                                    style={{ color: currentPage === 1 ? '' : '#008000' }}>Previous
                                                </button>
                                            </li>
                                            {Array.from({ length: totalPages }, (_, index) => (
                                                <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                    aria-current={currentPage === index + 1 ? 'page' : undefined}
                                                    key={index + 1}>
                                                    <button className="page-link" onClick={() => goToPage(index + 1)}
                                                        disabled={currentPage === index + 1}
                                                        style={{ color: currentPage === index + 1 ? '' : '#008000' }}>{index + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={goToNextPage}
                                                    disabled={currentPage === totalPages}
                                                    style={{ color: currentPage === totalPages ? '' : '#008000' }}>Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    {/*</div>*/}
                                </form>
                                {isSub && (
                                    <button type="button" className="btn btn-success w-25"
                                        onClick={() => {
                                            setIsMatchingVisible(true);
                                            setIsPreviewVisible(false);
                                        }}
                                    >Proceed to Field Matching
                                    </button>
                                )}

                            </div>
                        </>
                    )}

                    {/*Matching*/}
                    {isMatchingVisible && (
                        <div className="container">
                            <div>
                                <ArrowLeftSquare style={{ fontSize: '2.5rem' }} onClick={() => {
                                    setIsPreviewVisible(true);
                                    setIsMatchingVisible(false);
                                }}
                                />
                                <br />
                                <br />

                                <div className="row">
                                    <div className="col">
                                        <img
                                            src={isTableVisible ? hiddenIcon : visibilityIcon}
                                            alt="Eye Icon"
                                            onClick={toggleTableVisibility}
                                        />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-3 d-flex justify-content-start">
                                        <button classtype="button"
                                            className="btn btn-success"
                                            style={{
                                                backgroundColor: '#47CA82',
                                                borderColor: '#47CA82',
                                                width: 250,
                                                fontSize: '12px'
                                            }}>Source Column
                                        </button>
                                    </div>
                                    <div className="col-3 d-flex justify-content-start">
                                        <button classtype="button"
                                            className="btn btn-success"
                                            style={{
                                                backgroundColor: '#47CA82',
                                                borderColor: '#47CA82',
                                                width: 250,
                                                fontSize: '12px'
                                            }}>Target Column
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-3 d-flex justify-content-start">
                                        <div style={{ maxHeight: "30rem" }}>
                                            <Scrollbar style={{ width: 250, height: 600 }}>
                                                <table className="table table-hover">
                                                    {!isHeaderData ? (
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div>
                                                                        {data[0].map((cell, columnIndex) => (
                                                                            <div
                                                                                className="grid-item-column-source"
                                                                                draggable
                                                                                style={{
                                                                                    backgroundColor: sourceColorMap.hasOwnProperty(cell) ? sourceColorMap[cell] : 'white',
                                                                                    color: sourceColorMap.hasOwnProperty(cell) ? 'white' : 'black'
                                                                                }}
                                                                                key={columnIndex}
                                                                                onDragStart={(event) =>
                                                                                    handleDragStart(event, cell, true)
                                                                                }
                                                                            >
                                                                                {cell}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    ) : (
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div>
                                                                        {data[0].map((cell, columnIndex) => (
                                                                            <div
                                                                                className="grid-item-column-source"
                                                                                draggable
                                                                            >
                                                                                Column {columnIndex}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    )}
                                                </table>
                                            </Scrollbar>
                                        </div>

                                    </div>
                                    <div className="col-3 d-flex justify-content-start">
                                        <div>
                                            {columnKeys.map((columnKey) => (
                                                <div>
                                                    <div
                                                        className="grid-item-column-destination"
                                                        onDragOver={handleDragOver}
                                                        style={{
                                                            backgroundColor:
                                                                destColorMap.hasOwnProperty(columnKey.displayName) ?
                                                                    destColorMap[columnKey.displayName] : 'white',
                                                            color: destColorMap.hasOwnProperty(columnKey.displayName) ?
                                                                'white' : 'black'
                                                        }}
                                                        onDrop={(event) =>
                                                            handleDrop(event, columnKey.dbName, null, true)
                                                        }
                                                    >
                                                        {columnKey.displayName}
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-6" >
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <button className="btn btn-danger"
                                            style={{
                                                width: 200,
                                                height: 80,
                                                fontSize: '20px'
                                            }}
                                            onClick={() => deleteColumn()}>Reset
                                        </button>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>

                                        <button className="btn btn-info"
                                            style={{

                                                width: 200,
                                                height: 80,
                                                fontSize: '20px'
                                            }}

                                            onClick={() => {
                                                setIsMatchingTableVisible(true);
                                                setIsMatchingVisible(false);
                                            }}
                                        >Proceed
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isMatchingTableVisible && (
                        <div>
                            <ArrowLeftSquare style={{ fontSize: '2.5rem' }} onClick={() => {
                                setIsMatchingTableVisible(false);
                                setIsMatchingVisible(true);
                            }}
                            />
                            <br />
                            <br />
                            <Scrollbar style={{ width: 1400, height: 600 }}>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr className="table-secondary ">
                                            {columnKeys.map((columnKey) => (
                                                <th scope="col" >
                                                    {columnKey.displayName}
                                                    {/*{JSON.stringify(config.mongoDB)}*/}
                                                    {/*<button onClick={() => deleteColumn(columnKey.dbName)}>*/}
                                                    {/*    X*/}
                                                    {/*</button>*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                        className="bi bi-trash" viewBox="0 0 16 16"
                                                        onClick={() => deleteColumn(columnKey.dbName)}
                                                    >
                                                        <path
                                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                        <path
                                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />

                                                    </svg>
                                                </th>
                                            ))}
                                            <th scope="col" >
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderGridItems()}
                                    </tbody>
                                </table>
                                <span >
                                    Go to page :
                                    <input
                                        type="number"
                                        id="page"
                                        name="page"
                                        min="1"
                                        max={totalPagesImport}
                                        value={currentPageImport}
                                        onChange={(event) => {
                                            if (event.target.value > totalPagesImport) {
                                                setCurrentPageImport(totalPagesImport);
                                            } else if (event.target.value < 1) {
                                                setCurrentPageImport(1);
                                            } else {
                                                setCurrentPageImport(event.target.value);
                                            }
                                        }}
                                    /> &nbsp;
                                </span>
                                <span>
                                    Page {currentPageImport} of {totalPagesImport} &nbsp;
                                </span>
                            </Scrollbar >
                            <br />
                            <div >
                                <button className="btn btn-primary"
                                    onClick={() => handleUpload()}>Upload all
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
            {
                <Modal
                    show={showModal}
                    onHide={() => {
                        setShowModal(false);
                        setSuccessfulUploads(0);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Upload Status
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {infoMessage && infoMessage}
                        </p>
                        <p>
                            {errorMessage && errorMessage}
                        </p>
                        {
                            uploadFinish && (<p>
                                Successfully uploaded {successfulUploads} items.
                            </p>)
                        }
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-success"
                                onClick={() => navigate('/home')}>Back to Home page
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default ImportData;

const bread = [];
bread.push(
    "green chilli",
    "red onions",
    "sinqua",
    "ketchup",
    "mayonnaise",
    "yeast",
    "ginger",
    "green chili",
    "onion",
    "garlic"
);

export default bread;
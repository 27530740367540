import React, { useState } from 'react';
import * as d3 from "d3";
import styles from "../index.css";
import cashier from "../images/cashier.png";

function CashierComponent({ x, y, width, height, onMouseEnter, onMouseLeave }) {

  const renderShape = () => {
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        rx="15"
        opacity={1}
        fill="#DDDDDD"
        stroke="#000000"
        fillOpacity={1}
        strokeWidth={2}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  };

  const imageWidth = width / 2;
  const imageHeight = height / 2;

  return (
    <svg>
      {/* {renderShape()} */}
      <image
        href={cashier}
        x={x + (width / 2) - (imageWidth / 2)}
        y={y + (height / 2) - (imageHeight / 2)}
        width={imageWidth}
        height={imageHeight}
      />
    </svg>
  );
}

export default CashierComponent;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';

const StartRegisterScreen = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <img src={logo} alt="Logo" style={{ width: '450px', height: '110px' }} />
        <p style={styles.content}>
          AI reduces cognitive load on shoppers, creating a more enjoyable shopping experience
        </p>
        <p style={styles.content}>
          Increases customer retention / brand loyalty through improved shopping experience
        </p>
        <p style={styles.content}>
          Saves customer time by allowing payment through the cart system
        </p>
        <p style={styles.content}>
          Increases purchase amount by 30% on average through personalized recomemendations and item promotion
        </p>
        <div style={styles.progressContainer}>
          <span style={styles.progressDotActive}></span>
          <span style={styles.progressDot}></span>
          <span style={styles.progressDot}></span>
        </div>
      </div>
      <div style={styles.rightPanel}>
        <h1 style={styles.title}>Ready to create your business account?</h1>
        <div style={styles.infoBox}>
          <p>To complete the sign up process, please ensure you have the following details ready:</p>
          <ul style={styles.list}>
            <li>Business details: email, address, employer identification number, bank account, etc...</li>
            <li>Responsible person information: phone number, address, SSN</li>
          </ul>
          <button onClick={() => navigate('/register')} style={styles.button}>Start Sign Up</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  leftPanel: {
    flex: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  rightPanel: {
    flex: 1,
    backgroundColor: '#AAFFCC88',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  title: {
    marginBottom: '20px',
  },
  content: {
    textAlign: 'center',
    marginBottom: '20px', // Add margin for spacing before progress dots
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  progressDot: {
    height: '10px',
    width: '10px',
    backgroundColor: 'grey',
    borderRadius: '50%',
    margin: '5px',
  },
  progressDotActive: {
    height: '10px',
    width: '10px',
    backgroundColor: '#66EE77', // Active dot color
    borderRadius: '50%',
    margin: '5px',
  },
  infoBox: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  button: {
    backgroundColor: '#338833',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    marginTop: '20px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
};

export default StartRegisterScreen;

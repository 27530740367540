// ImportData.js
import React from "react";
import Sidebar from "../components/sidebar";
import ImportDataComponent from "../importData/ImportData"; // Replace with your actual import

const ImportData = () => {
  console.log("ImportData.js");



  return (
    <div style={styles.screenContainer}>
      <Sidebar />
      <div style={styles.screenContent}>
        <ImportDataComponent />
        {/* Additional Import Data content goes here */}
      </div>
    </div>
  );
};

const styles = {
  screenContainer: {
    display: "flex",
  },
  screenContent: {
    marginLeft: "250px",
    padding: "20px",
    flex: 1,
  },
};

export default ImportData;

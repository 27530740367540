import React from 'react';
import door from '../images/door.png';
import styles from "../index.css";

function DoorComponent({ x, y, width, height, rotate }) {

  const renderShape = () => {
    const radius = Math.min(width, height) / 2;
    const cx = x + radius;
    const cy = y + radius;
    
    return (
      <image
        href={door}
        x={cx}
        y={cy}
        width={width}
        height={height}
        transform={`rotate(${rotate}, ${cx}, ${cy})`}
      />
    );
  };

  return (
    <svg>
      {renderShape()}
    </svg>
  );
}

export default DoorComponent;

const produce = [];
produce.push(
  "potato",
  "yellow cucumbers",
  "carrot",
  "bitter melon",
  "brinjal",
  "cabbage",
  "calabash",
  "capsicum",
  "cauliflower",
  "lady finger",
  "potato",
  "sponge gourd",
  "tomato"
);

export default produce;

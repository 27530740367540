import React from 'react';
import styles from "../index.css";


function CheckoutTextComponent({ x, y, width, height }) {
  const fontSize = Math.min(width, height) / 8; // Adjust this as needed

  return (
    <text 
      size
      x={x} 
      y={y + fontSize} // 'y' position is the baseline of text in SVG, added fontSize to adjust
      fontSize={fontSize} 
      fontFamily="Montserrat, sans-serif" // Example font family, adjust as needed
      fill="black" // Color of the text
    >
      Checkout
    </text>
  );
}

export default CheckoutTextComponent;

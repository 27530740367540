import * as d3 from "d3";
import { AxisLeft } from "./AxisLeft";
import { AxisBottom } from "./AxisBottom";
import { Tooltip } from "./Tooltip";
import { useState, useEffect } from "react";

import Heatzone from "./components/heatzone.js";
import DoorComponent from "./components/doors";
import CashierComponent from "./components/cashier";
import CheckoutText from "./components/checkoutText"
import EntranceText from "./components/entranceText";

const MARGIN = { top: -10, right: -10, bottom: -10, left: -10 };

export const Scatterplot = ({ width, height, data }) => {
  useEffect(() => {
    console.log("Scatter effect", data);
  }, [data]);

  const [hovered, setHovered] = useState(null);
  // Layout. The div size is set by the given props.
  // The bounds (=area inside the axis) is calculated by substracting the margins
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;


  function interpolateHeatColor(value) {
    // Define your color stops
    const colorStops = [
      { stop: 0, color: [0, 0, 255] },     // blue
      { stop: 0.25, color: [255, 255, 0] }, // yellow
      { stop: 0.5, color: [0, 255, 0] },   // green
      { stop: 0.75, color: [255, 165, 0] },// orange
      { stop: 3, color: [255, 0, 0] }      // red
    ];
  
    // Find which stops the value is between
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (value >= colorStops[i].stop && value < colorStops[i + 1].stop) {
        const weight = (value - colorStops[i].stop) / (colorStops[i + 1].stop - colorStops[i].stop);
        const color = [
          colorStops[i].color[0] + weight * (colorStops[i + 1].color[0] - colorStops[i].color[0]),
          colorStops[i].color[1] + weight * (colorStops[i + 1].color[1] - colorStops[i].color[1]),
          colorStops[i].color[2] + weight * (colorStops[i + 1].color[2] - colorStops[i].color[2]),
        ];
        return `rgb(${color.map(c => Math.round(c)).join(",")})`;
      }
    }
  }

  function calcHeat(heat) {
    const t = data.dTotal;
  
    if (heat === 0) {
      return "rgb(170, 170, 170)";
    }
  
    if (data.dTotal > 10) {
      const normalizedHeat = 3 * heat / data.dTotal;
      return interpolateHeatColor(normalizedHeat);
    } else {
      heat = heat * 20;
      let R = heat;
      let G = Math.floor(255 - 255 * (Math.abs(R - 128) / 128));
      let B = 255 - R;
  
      return `rgb(${R},${G},${B})`;
    }
  }


  // Scales
  const xScale = d3
    .scaleLinear()
    .domain([-73.8, -73.82])
    .range([0, boundsWidth]);
  const yScale = d3
    .scaleLinear()
    .domain([41.03, 41.06])
    .range([boundsHeight, 0]);

  //console.log("data", data);

  // Build the shapes

  return (
    <div style={{}}>
      <svg style={{ border: '2px solid black' }} width={width} height={height}>
        {/* first group is for the violin and box shapes */}
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          {/* Y axis */}
          {/*<AxisLeft yScale={yScale} pixelsPerTick={40} width={boundsWidth} />*/}

          {/* X axis, use an additional translation to appear at the bottom */}
          <g transform={`translate(0, ${boundsHeight})`}>
            {/*<AxisBottom
              xScale={xScale}
              pixelsPerTick={40}
              height={boundsHeight}
          />*/}
          </g>

          {/* Circles */}
          {/*allShapes*/}


        {/* Produce */}
        <Heatzone 
          name={"Produce"}
          x={xScale(-73.813)} 
          y={yScale(41.044)} 
          width={100} 
          height={100} 
          fill={calcHeat(data.dTotalProduce)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.813) + 200,
              yPos: yScale(41.044) + 200,
              name: "Produce",
              number: data.dTotalProduce,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="circle"
        />

        {/* Cooking */}
        <Heatzone 
          name={"Cooking"}
          x={xScale(-73.805)} 
          y={yScale(41.052)} 
          width={90} 
          height={300} 
          fill={calcHeat(data.dTotalCooking)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.805) + 200,
              yPos: yScale(41.052) + 200,
              name: "Cooking",
              number: data.dTotalCooking,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="rect"
        />


          {/* Dairy */}
          <Heatzone 
          name={"Dairy"}
          x={xScale(-73.801)} 
          y={yScale(41.052)} 
          width={90} 
          height={300} 
          fill={calcHeat(data.dTotalDairy)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.801) + 200,
              yPos: yScale(41.052) + 200,
              name: "Dairy",
              number: data.dTotalDairy,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="rect"
        />

          {/* Produce */}
          <Heatzone 
          name={"Produce"}
          x={xScale(-73.813)} 
          y={yScale(41.050)} 
          width={100} 
          height={100} 
          fill={calcHeat(data.dTotalProduce)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.813) + 200,
              yPos: yScale(41.050) + 200,
              name: "Produce",
              number: data.dTotalProduce,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="circle"
        />

        {/* Produce */}
        <Heatzone 
          name={"Produce"}
          x={xScale(-73.813)} 
          y={yScale(41.056)} 
          width={100} 
          height={100} 
          fill={calcHeat(data.dTotalProduce)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.813) + 200,
              yPos: yScale(41.056) + 200,
              name: "Produce",
              number: data.dTotalProduce,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="circle"
        />


        {/* Breakfast */}
        <Heatzone 
          name={"Meat / Seafood"}
          x={xScale(-73.801)} 
          y={yScale(41.058)} 
          width={460} 
          height={80} 
          fill={calcHeat(data.dTotalMeatSeafood)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.801) + 200,
              yPos: yScale(41.058) + 200,
              name: "Meat / Seafood",
              number: data.dTotalMeatSeafood,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="rect"
        />

        {/* Produce */}
        <Heatzone 
          name={"Dessert"}
          x={xScale(-73.801)} 
          y={yScale(41.036)} 
          width={300} 
          height={80} 
          fill={calcHeat(data.dTotalDessert)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.801) + 200,
              yPos: yScale(41.036) + 200,
              name: "Dessert",
              number: data.dTotalDessert,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="rect"
        />

        {/* Produce */}
        <Heatzone 
          name={"Fruits"}
          x={xScale(-73.817)} 
          y={yScale(41.058)} 
          width={80} 
          height={440} 
          fill={calcHeat(data.dTotalFruit)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.817) + 200,
              yPos: yScale(41.058) + 200,
              name: "Fruit",
              number: data.dTotalFruit,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="rect"
        />

          {/* Snacks */}
          <Heatzone 
          name={"Snacks"}
          x={xScale(-73.809)} 
          y={yScale(41.052)} 
          width={90} 
          height={300} 
          fill={calcHeat(data.dTotalSnacks)}
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.809) + 200,
              yPos: yScale(41.052) + 200,
              name: "Snacks",
              number: data.dTotalSnacks,
            })
          }
          onMouseLeave={() => setHovered(null)}
          shape="rect"
        />

        {/* Door */}
        <DoorComponent
          x={xScale(-73.8143)} 
          y={yScale(41.0335)} 
          width={90} 
          height={300} 
          rotate={270}
        />

        {/* Cashier Components */}
        <CashierComponent 
          x={xScale(-73.809)} 
          y={yScale(41.035)} 
          width={70} 
          height={80} 
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.809) + 200,
              yPos: yScale(41.037) + 200,
              name: "Checkout",
              number: 1,
            })
          }
          onMouseLeave={() => setHovered(null)}
        />
        <CashierComponent 
          x={xScale(-73.811)} 
          y={yScale(41.035)} 
          width={70} 
          height={80} 
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.811) + 200,
              yPos: yScale(41.037) + 200,
              name: "Checkout",
              number: 2,
            })
          }
          onMouseLeave={() => setHovered(null)}
        />
        <CashierComponent 
          x={xScale(-73.813)} 
          y={yScale(41.035)} 
          width={70} 
          height={80} 
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.813) + 200,
              yPos: yScale(41.037) + 200,
              name: "Checkout",
              number: 3,
            })
          }
          onMouseLeave={() => setHovered(null)}
        />
        <CashierComponent 
          x={xScale(-73.815)} 
          y={yScale(41.035)} 
          width={70} 
          height={80} 
          onMouseEnter={() =>
            setHovered({
              xPos: xScale(-73.815) + 200,
              yPos: yScale(41.037) + 200,
              name: "Checkout",
              number: 4,
            })
          }
          onMouseLeave={() => setHovered(null)}
        />
        <CheckoutText 
          x={xScale(-73.8115)} 
          y={yScale(41.0365)} 
          width={200} 
          height={200} 
        />
        <EntranceText
          x={xScale(-73.8165)} 
          y={yScale(41.0372)} 
          width={150} 
          height={150} 
        />
          
          
        </g>
      </svg>

      <div
        style={{
          width: boundsWidth,
          height: boundsHeight,
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
          marginLeft: MARGIN.left,
          marginTop: MARGIN.top,
        }}
      >
        <Tooltip interactionData={hovered} />
      </div>
    </div>
  );
};

export default Scatterplot;

import "react-widgets/styles.css";
import * as XLSX from "xlsx";

const openDataFile = (file) => {
  return new Promise((resolve, reject) => {
    let fileContent = null;
    const reader = new FileReader();
    let isFileExcel = false;

    // Check if file is Excel
    if (
      file.name.endsWith(".xlsx") ||
      file.name.endsWith(".xls") ||
      file.name.endsWith(".xlsm")
    ) {
      isFileExcel = true;

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData.length > 0) {
          isFileExcel = true;
          fileContent = jsonData;

          let returnData = parseDataToJSON(isFileExcel, fileContent);
          resolve(returnData);
        } else {
          let returnData = {
            errorMessage: "No data found in Excel file",
            data: null,
          };
          reject(returnData);
        }
      };

      reader.readAsArrayBuffer(file);
    } else {
      // If not Excel, check if txt/csv
      reader.onload = (e) => {
        const content = e.target.result;
        fileContent = content;
        let returnData = parseDataToJSON(isFileExcel, fileContent);
        resolve(returnData);
      };

      reader.readAsText(file);
    }
  });
};

function parseDataToJSON(isFileExcel, fileContent) {
  let actualSeparator = null;

  let headers = [];
  let lines = [];
  if (!isFileExcel) {
    lines = fileContent.split("\n");

    let firstLine = null;
    let lineNum = 0;
    // look for first line that contains at least 5 non empty columns
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > 4) {
        firstLine = lines[i];
        lineNum = i;
        break;
      }
    }

    // look for separator in first line
    if (firstLine.includes(";")) {
      actualSeparator = ";";
    } else if (firstLine.includes(",")) {
      actualSeparator = ",";
    } else if (firstLine.includes("\t")) {
      actualSeparator = "\t";
    } else if (firstLine.includes(".")) {
      actualSeparator = ".";
    } else {
      let returnData = {
        errorMessage: "No separator detected",
        data: null,
      };

      return returnData;
    }

    headers = firstLine.trim().split(actualSeparator);
    //ignore first lines including header
    lines = lines.slice(lineNum);
  } else {
    // headers are in first non empty line
    let lineNum = 0;

    for (let i = 0; i < fileContent.length; i++) {
      if (fileContent[i] !== "" && fileContent[i].length > 4) {
        headers = fileContent[i];
        lineNum = i;
        break;
      }
    }

    lines = fileContent.slice(lineNum);
  }

  // Simple check: Look for at least 5 headers and one data row

  if (headers.length < 5) {
    let returnData = {
      errorMessage: "Not enough columns",
      data: null,
    };

    return returnData;
  }

  if (lines.length < 1) {
    let returnData = {
      errorMessage: "No lines detected",
      data: null,
    };

    return returnData;
  }

  var dataTemp = [];

  var numLine = 0;
  var err = false;
  for (const element of lines) {
    numLine++;
    // no need to  check empty line

    if (element !== "") {
      let cells = [];
      if (!isFileExcel) {
        cells = element.trim().split(actualSeparator);
      } else {
        cells = element;
      }

      //count how many non empty elements in element array
      let nonEmptyCells = 0;
      for (let i = 0; i < cells.length; i++) {
        if (cells[i] && cells[i] !== "") {
          nonEmptyCells++;
        }
      }

      if (
        cells.length < 5 ||
        element === null ||
        element === "" ||
        nonEmptyCells < 5
      ) {
        console.log("Line " + numLine + " is invalid");
      } else {
        dataTemp.push(cells);
      }
    }

    if (err) {
      console.log("err", err);

      return null;
    }
  }

  let returnData = {
    data: dataTemp,
  };

  return returnData;
}

export default openDataFile;

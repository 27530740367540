const produce = [];
produce.push(
  "banana",
  "cucumber",
  "orange",
  "pear",
  "pineapple",
  "sugar apple",
  "lemon",
  "strawberry",
  "apple",
  "dragon fruit",
  "guava",
  "oren",
);

export default produce;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import ViewData from '../importData/ViewData';

const ImportDataEdit = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.screenContainer}>
      <Sidebar />
      <div style={styles.screenContent}>
        {/* Button to navigate to Home Screen */}
        <button
          style={styles.userSettingsButton}
          onClick={() => navigate('/')}
        >
          Settings
        </button>
        <ViewData />
        {/* Additional Import Data Edit content goes here */}
      </div>
    </div>
  );
};

const styles = {
  screenContainer: {
    display: 'flex',
    width: '100%',
  },
  screenContent: {
    marginLeft: '250px',
    padding: '20px',
    width: 'calc(100% - 250px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  userSettingsButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    padding: '10px 20px',
    background: '#338833',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  }
};

export default ImportDataEdit;

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import config from "../getConfig.js";

// for firebase
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../config/config-firebase";

const countryCodes = [
  { code: "US", dial_code: "+1" },
  { code: "MX", dial_code: "+52" },
  { code: "HK", dial_code: "+852" }, // for remi-testing
];

const RegistrationScreen = () => {
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [businessZip, setBusinessZip] = useState("");
  const [EINnumber, setEINnumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");

  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [businessUsername, setBusinessUsername] = useState("");
  const [password, setPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [ssn, setSsn] = useState("");

  const navigate = useNavigate();

  const [questionSet, setQuestionSet] = useState(1);

  const [user, setUser] = useState(null);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);

  // new states for handling phone auth
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmObj, setConfirmObj] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState("");

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[0].dial_code
  );

  const isPhoneNumberValid = () => {
    const pattern = /^\+[0-9\s\-\(\)]+$/;
    return pattern.test(combinedPhoneNumber);
  };

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const combinedPhoneNumber = selectedCountryCode + phoneNumber;

  useEffect(() => {
    // Authentication state listener
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // Optionally, fetch additional user info or navigate
        navigate("/home");
        window.location.reload();
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    initializeRecaptchaVerifier(); // Call this function directly here
    return () => {
      // Cleanup function moved inside useEffect
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        delete window.recaptchaVerifier;
      }
    };
  }, []); // Ensured this useEffect is called once

  const initializeRecaptchaVerifier = useCallback(() => {
    const recaptchaContainerId = "recaptcha-container";
    let recaptchaContainer = document.getElementById(recaptchaContainerId);
    if (!recaptchaContainer) {
      recaptchaContainer = document.createElement("div");
      recaptchaContainer.id = recaptchaContainerId;
      document.body.appendChild(recaptchaContainer); // Append to body if not exists
    } else {
      recaptchaContainer.innerHTML = ""; // Clear if exists
    }

    window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainer, {
      size: "invisible",
      callback: (response) => {
        console.log("Recaptcha verified", response);
      },
    });
  }, []);

  const sendVerificationCode = useCallback(async () => {
    if (!isPhoneNumberValid()) {
      alert("Please enter a valid phone number.");
      return;
    }
    setIsSigningIn(true);
    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        combinedPhoneNumber,
        window.recaptchaVerifier
      );
      setConfirmObj(confirmationResult);
      setIsSigningIn(false);
      setCodeSent(true);
    } catch (error) {
      console.error("Error during signInWithPhoneNumber", error);
      alert(`Failed to send verification code: ${error.message}`);
      setIsSigningIn(false);
      if (window.recaptchaVerifier) window.recaptchaVerifier.reset();
    }
  }, [combinedPhoneNumber, isPhoneNumberValid]);

  // Handle the next button click
  const handleNextClick = (event) => {
    event.preventDefault();

    // Check if it's the step for code verification
    if (questionSet === 3) {
      if (codeSent && !user) {
      } else if (!codeSent) {
        // If the code has not been sent, attempt to send it
        sendVerificationCode();
      }
    } else {
      // For other question sets, proceed as normal
      setQuestionSet(questionSet + 1);
    }
  };

  // for data retrieval
  const serverAddress = config.serverAddress;
  const serverPort = config.serverPort;

  const API_URL = config.serverUrl + "registerMerchant";

  const submitRegistrationData = async (token) => {
    // Combine all your form data into one object
    const registrationData = {
      token,
      businessName,
      businessAddress,
      businessCity,
      businessState,
      businessCountry,
      businessZip,
      EINnumber,
      bankName,
      accountNumber,
      routingNumber,
      businessEmail,
      businessUsername,
      businessPhoneNumber,
      // password,
      firstName,
      lastName,
      email,
      phoneNumber: combinedPhoneNumber,
      address,
      city,
      state,
      country,
      zip,
      ssn,
    };

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registrationData),
      });

      const responseData = await response.json();
      if (response.ok) {
        console.log("Registration successful:", responseData);
        navigate("/"); // Navigate to the next page after successful registration
      } else {
        console.error("Registration failed:", responseData);
      }
    } catch (error) {
      console.error("Error submitting registration data:", error);
    }
  };

  // Function to verify the code entered by the user and create account
  const verifyCodeAndCreateAccount = useCallback(() => {
    if (!confirmObj) {
      console.error("Confirmation object not available.");
      alert("Verification process not initialized. Please request a new code.");
      return;
    }
    setIsVerifyingCode(true);
    confirmObj
      .confirm(verificationCode)
      .then(async (result) => {
        setIsVerifyingCode(false);
        const user = result.user;
        const token = await user.getIdToken();
        submitRegistrationData(token); // Assuming this function navigates on success
      })
      .catch((error) => {
        console.error("Error while checking the verification code", error);
        setIsVerifyingCode(false);
        // Alert the user about the specific error
        alert(
          `Invalid verification code. Please try again. Verification failed: ${error.message}`
        );
        setError("Invalid verification code. Please try again.");
        setVerificationCode("");
        // Optionally reset reCAPTCHA here if necessary
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.reset(window.recaptchaWidgetId);
        }
      });
  }, [verificationCode, confirmObj, submitRegistrationData]);

  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <img
          src={logo}
          alt="Logo"
          style={{ width: "450px", height: "110px" }}
        />
        <p style={styles.content}>
          Welcome to EzOut, the ultimate solution for your business needs.
        </p>
        {questionSet === 1 ? (
          <div style={styles.progressContainer}>
            <span style={styles.progressDotActive}></span>
            <span style={styles.progressDot}></span>
            <span style={styles.progressDot}></span>
          </div>
        ) : questionSet === 2 ? (
          <div style={styles.progressContainer}>
            <span style={styles.progressDot}></span>
            <span style={styles.progressDotActive}></span>
            <span style={styles.progressDot}></span>
          </div>
        ) : (
          <div style={styles.progressContainer}>
            <span style={styles.progressDot}></span>
            <span style={styles.progressDot}></span>
            <span style={styles.progressDotActive}></span>
          </div>
        )}
      </div>
      <div style={styles.rightPanel}>
        {questionSet === 1 ? (
          <h2 style={styles.formTitle}>
            Let us learn more about your business!
          </h2>
        ) : questionSet === 2 ? (
          <h2 style={styles.formTitle}>Let's set up your business account!</h2>
        ) : (
          <h2 style={styles.formTitle}>Who should we reach out to?</h2>
        )}

        <div style={styles.formContainer}>
          <form onSubmit={handleNextClick} style={styles.form}>
            {questionSet === 1 ? (
              <div>
                <label style={styles.label}>Business Name:</label>
                <input
                  type="text"
                  placeholder="Business Name"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business Address:</label>
                <input
                  type="text"
                  placeholder="Business Address"
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business City:</label>
                <input
                  type="text"
                  placeholder="City"
                  value={businessCity}
                  onChange={(e) => setBusinessCity(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business State:</label>
                <input
                  type="text"
                  placeholder="State / Provine"
                  value={businessState}
                  onChange={(e) => setBusinessState(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business Country:</label>
                <input
                  type="text"
                  placeholder="Country"
                  value={businessCountry}
                  onChange={(e) => setBusinessCountry(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business ZIP:</label>
                <input
                  type="text"
                  placeholder="ZIP / Postal Code"
                  value={businessZip}
                  onChange={(e) => setBusinessZip(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business EIN Number:</label>
                <input
                  type="text"
                  placeholder="EIN Number"
                  value={EINnumber}
                  onChange={(e) => setEINnumber(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business Bank Name:</label>
                <input
                  type="text"
                  placeholder="Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business Account Number:</label>
                <input
                  type="text"
                  placeholder="Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business Routing Number:</label>
                <input
                  type="text"
                  placeholder="Routing Number"
                  value={routingNumber}
                  onChange={(e) => setRoutingNumber(e.target.value)}
                  style={styles.input}
                />

                <button type="submit" style={styles.button}>
                  Next
                </button>
              </div>
            ) : questionSet === 2 ? (
              <div>
                <label style={styles.label}>Email:</label>
                <input
                  type="text"
                  placeholder="Business Email"
                  value={businessEmail}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Business Phone Number:</label>
                <input
                  type="text"
                  placeholder="Business Phone Number"
                  value={businessPhoneNumber}
                  onChange={(e) => setBusinessPhoneNumber(e.target.value)}
                  style={styles.input}
                />

                <label style={styles.label}>Username:</label>
                <input
                  type="text"
                  placeholder="Business Username"
                  value={businessUsername}
                  onChange={(e) => setBusinessUsername(e.target.value)}
                  style={styles.input}
                />

                {/* <label style={styles.label}>Password:</label>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={styles.input}
                /> */}

                <button type="submit" style={styles.button}>
                  Next
                </button>
              </div>
            ) : (
              questionSet === 3 && (
                <div>
                  {/* Inputs for First Name, Last Name, etc. */}
                  <label style={styles.label}>First Name:</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>Last Name:</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>Email:</label>
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>
                    Phone Number to Get Code: *
                  </label>
                  <div style={styles.inputGroup}>
                    <select
                      value={selectedCountryCode}
                      onChange={handleCountryCodeChange}
                      style={{ ...styles.input, ...styles.select }}
                    >
                      {countryCodes.map((country) => (
                        <option key={country.code} value={country.dial_code}>
                          {country.dial_code}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      style={{ ...styles.input, borderRadius: "0 5px 5px 0" }}
                    />
                  </div>

                  <label style={styles.label}>Address:</label>
                  <input
                    type="text"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>City:</label>
                  <input
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>State:</label>
                  <input
                    type="text"
                    placeholder="State / Provine"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>Country:</label>
                  <input
                    type="text"
                    placeholder="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>ZIP:</label>
                  <input
                    type="text"
                    placeholder="ZIP / Postal Code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    style={styles.input}
                  />

                  <label style={styles.label}>Last 4 Digits of SSN:</label>
                  <input
                    type="text"
                    placeholder="Last 4 Digits of Social Security Number"
                    value={ssn}
                    onChange={(e) => setSsn(e.target.value)}
                    style={styles.input}
                  />
                  {!codeSent ? (
                    <button
                      onClick={sendVerificationCode}
                      style={styles.button}
                    >
                      Request Code for Account Creation
                    </button>
                  ) : (
                    <>
                      {/* <label style={styles.label}>Phone Number for Verification:</label>
                    <input
                      type="text"
                      readOnly
                      value={phoneNumber}
                      style={styles.input}
                    /> */}
                      <label style={styles.label}>
                        Enter Verification Code:
                      </label>
                      <input
                        type="text"
                        placeholder="Verification Code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        style={styles.input}
                      />
                      <button
                        onClick={verifyCodeAndCreateAccount}
                        style={styles.button}
                      >
                        Verify and Create Account
                      </button>
                      {error && <div style={{ color: "red" }}>{error}</div>}
                    </>
                  )}
                </div>
              )
            )}
            {/* Add a hidden div for the reCAPTCHA verifier */}
            <div
              id="recaptcha-container"
              style={{ visibility: "hidden" }}
            ></div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  inputGroup: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  select: {
    flex: "none",
    border: "1px solid #ccc",
    padding: "10px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    marginRight: "2px",
    width: "auto",
  },
  // input: {
  //   flex: '1',
  //   padding: '10px',
  //   border: '1px solid #ccc',
  //   borderTopRightRadius: '5px',
  //   borderBottomRightRadius: '5px',
  //   marginLeft: '0',
  // },

  container: {
    display: "flex",
    height: "100vh",
  },
  leftPanel: {
    flex: 1,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
  rightPanel: {
    flex: 1,
    backgroundColor: "#AAFFCC88",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
  title: {
    marginBottom: "20px",
  },
  content: {
    textAlign: "center",
    marginBottom: "20px", // Add margin for spacing before progress dots
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
  },
  progressDot: {
    height: "10px",
    width: "10px",
    backgroundColor: "grey",
    borderRadius: "50%",
    margin: "5px",
  },
  progressDotActive: {
    height: "10px",
    width: "10px",
    backgroundColor: "#66EE77", // Active dot color
    borderRadius: "50%",
    margin: "5px",
  },
  infoBox: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%", // Adjust as needed
    alignItems: "flex-start", // Align children to the start
  },
  formTitle: {
    color: "#227722",
    marginBottom: "20px",
    marginTop: "2px",
    alignSelf: "flex-start",
    marginLeft: "3vw",
  },
  input: {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "95%",
  },
  button: {
    backgroundColor: "#338833",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    marginTop: "10px",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  formContainer: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "80%", // Adjust as needed
    height: "65vh", // Increased height
    overflowY: "scroll", // Enable vertical scrolling
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align content to the start
  },
  label: {
    textAlign: "left", // Align text to the left
    width: "100%", // Ensure label takes full width
    margin: "10px 0",
  },
  // ... other styles
};

export default RegistrationScreen;

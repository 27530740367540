import React, { useState, useEffect } from "react";
import Scatterplot from "./Scatter";
import scale from "./images/heatmap-scale.jpeg";

import cooking from "./products/cooking.js";
import meatSeafood from "./products/meat-seafood.js";
import dairy from "./products/dairy.js";
import dessert from "./products/dessert.js";
import fruit from "./products/fruit.js";
import produce from "./products/produce.js";
import snacks from "./products/snacks.js";

import configData from "../src/config/config.json";

console.log("configData", configData);

var total = 0;
var totalMeatSeafood = 0;
var totalCooking = 0;
var totalDairy = 0;
var totalDessert = 0;
var totalFruit = 0;
var totalProduce = 0;
var totalSnacks = 0;

function Sqs() {
  var dataSqs = {
    dTotalMeatSeafood: 0,
    dTotalCooking: 0,
    dTotalDairy: 0,
    dTotalDessert: 0,
    dTotalFruit: 0,
    dTotalProduce: 0,
    dTotalSnacks: 0,
    dTotal: 0,
  };

  const [reload, setReload] = useState(false);

  function addCategory(product) {
    if (cooking.indexOf(product) > -1) {
      totalCooking++;
      total++;
    }

    if (meatSeafood.indexOf(product) > -1) {
      totalMeatSeafood++;
      total++;
    }

    if (dairy.indexOf(product) > -1) {
      totalDairy++;
      total++;
    }

    if (dessert.indexOf(product) > -1) {
      totalDessert++;
      total++;
    }

    if (fruit.indexOf(product) > -1) {
      totalFruit++;
      total++;
    }

    if (produce.indexOf(product) > -1) {
      totalProduce++;
      total++;
    }

    if (snacks.indexOf(product) > -1) {
      totalSnacks++;
      total++;
    }

    setReload(true);
  }

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  async function receive() {
    const res = await fetch(
      configData.serverAddress + ":" + configData.serverPort + "/receive"
    );

    const data = await res.json();

    if (typeof data.Messages !== "undefined") {
      data.Messages.map((message) => {
        if (message.Body) {
          const body = JSON.parse(message.Body);
          console.log("le product", body.data.category);
          addCategory(body.data.category);
        }
      });
    }
  }

  useEffect(function appRunTimer() {
    // Creates a new timer when mount the component.

    const timer = setInterval(() => {
      receive();
    }, 5000);
    // Stops the old timer when umount the component.
    return function stopTimer() {
      clearInterval(timer);
    };
  }, []);

  /*
  const app = Consumer.create({
    queueUrl: configData.QueueUrl,
    handleMessage: async (message) => {
      let category = JSON.parse(message.Body).data.category;
      test(category);
      app.deleteMessage(message);

      setReload(true);
    },
    sqs: new SQSClient({
      region: configData.Region,
      credentials: {
        accessKeyId: configData.AccessKey,
        secretAccessKey: configData.SecretAccessKey,
      },
    }),
  });
  app.on("error", (err) => {
    console.error(err.message);
  });

  app.on("processing_error", (err) => {
    console.error(err.message);
  });

  app.on("timeout_error", (err) => {
    console.error(err.message);
  });
  app.start();
  */

  dataSqs.dTotalMeatSeafood = totalMeatSeafood;
  dataSqs.dTotalCooking = totalCooking;
  dataSqs.dTotalDairy = totalDairy;
  dataSqs.dTotalDessert = totalDessert;
  dataSqs.dTotalFruit = totalFruit;
  dataSqs.dTotalProduce = totalProduce;
  dataSqs.dTotalSnacks = totalSnacks;
  dataSqs.dTotal = total;

  return (
    <>
      {console.log("dataSqs", dataSqs)}
      <div style={{ textAlign: "center", marginBottom: -5 }}>
        <h1>Store Heatmap </h1>
        {/* <button onPress={() => console.log("console.log: dataSqs", dataSqs)}>
          <form>IMPORT SQS</form>
        </button> */}
        <img
          style={{
            position: "absolute",
            left: 1240,
            top: 93,
            width: 100,
            height: 170,
          }}
          src={scale}
          alt="Heatmap Scale"
        />
        <br />
      </div>

      <Scatterplot data={dataSqs} width={900} height={650} />
    </>
  );
}

export default Sqs;

// MapSales.js
import React from 'react';
import Sidebar from '../components/sidebar';
import Sqs from '../Sqs'; // Replace with your actual import

const MapSales = () => {
  return (
    <div style={styles.screenContainer}>
      <Sidebar />
      <div style={styles.screenContent}>
        <Sqs />
        {/* Additional Map Sales content goes here */}
      </div>
    </div>
  );
};

const styles = {
  screenContainer: {
    display: 'flex',
  },
  screenContent: {
    marginLeft: '250px',
    padding: '20px',
    flex: 1,
  },
};

export default MapSales;

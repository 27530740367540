const columnKeys = [
    { displayName: "Title", dbName: "title" },
    { displayName: "Description", dbName: "description" },
    // { displayName: "Slug", dbName: "slug" },
    { displayName: "Regular price", dbName: "regular_price" },
    { displayName: "Discount price", dbName: "discount_price" },
    { displayName: "UPC", dbName: "upc" },
    // { displayName: "Barcode", dbName: "barcode_no" },
    { displayName: "EAN", dbName: "ean" },
    { displayName: "Tax Rate", dbName: "tax_rate" },
    { displayName: "Taxability", dbName: "taxability" },

];

module.exports = columnKeys;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
  RecaptchaVerifier
} from 'firebase/auth';
import { auth } from '../config/config-firebase';
import Alert from 'react-s-alert';

function LoginScreen() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [otp, setOTP] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate(`/edit-data`);
      }
    });

    setupReCaptcha();
  }, [navigate]);

  const setupReCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved - signInWithPhoneNumber can proceed
      }
    });
    window.recaptchaVerifier.render();
  };

  const requestOTP = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      window.confirmationResult = confirmationResult;
      setExpandForm(true);
      Alert.success('OTP has been sent.');
    } catch (error) {
      Alert.error(`Failed to send OTP: ${error.message}`);
    } finally {
      setProcessing(false);
      document.getElementById('recaptcha-container').style.display = 'none';
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const result = await window.confirmationResult.confirm(otp);
      Alert.success("You're successfully logged in!");
      navigate(`/edit-data`);
    } catch (error) {
      Alert.error(`Failed to verify OTP: ${error.message}`);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="centered-container">
      <div className="login-container">
        <div className="login-content">
          <h1 className="login-title">Login to EzStore</h1>
          {!expandForm ? (
            <form onSubmit={requestOTP}>
              <div className="form-item">
                <input type="tel" className="form-control" placeholder="Phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
              </div>
              <button type="submit" className="btn btn-block btn-primary" disabled={isProcessing}>Request Code</button>
            </form>
          ) : (
            <form onSubmit={verifyOTP}>
              <div className="form-item">
                <input type="number" className="form-control" placeholder="OTP" value={otp} onChange={(e) => setOTP(e.target.value)} required />
              </div>
              <button type="submit" className="btn btn-block btn-primary" disabled={isProcessing}>Verify Code</button>
            </form>
          )}
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </div>
  );
}


export default LoginScreen;
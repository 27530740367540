import config from "./config/config.json";

let localConfig;
try {
  localConfig = require("./config/local config.json");
} catch (error) {
  // localConfig file not present
}

if (localConfig) {
  config = localConfig;
}

export default config;

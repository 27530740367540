import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table } from "antd";
import columnKeys from "../constants/columns.js";
import Spinner from "react-bootstrap/Spinner";
import "./ViewData.css";
import config from "../getConfig.js";

const ViewData = () => {
  const [data, setData] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(
    "Loading, please wait..."
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    fetchImports();
  }, []);

  const fetchImports = async () => {
    setLoadingMessage("Loading, please wait...");
    try {
      const serverAddress = config.serverAddress;
      const serverPort = config.serverPort;

      const response = await fetch(
        config.serverUrl + "/getImports"
      );
      const imports = await response.json();

      if (!response.ok)
        throw new Error(imports.error || "Failed to fetch data");

      const formattedData = imports.map((item, index) => ({
        ...item,
        key: index.toString(),
      }));
      setData(formattedData);
      setLoadingMessage("");
    } catch (error) {
      setLoadingMessage(error.toString());
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = columnKeys.map((column) => ({
    title: column.displayName,
    dataIndex: column.dbName,
    key: column.dbName,
    ...getColumnSearchProps(column.dbName),
  }));

  return (
    <div>
      <h2>Imports</h2>
      {/*{loadingMessage*/}
      {/*    || <Table columns={columns} dataSource={data} />}*/}
      {loadingMessage ? (
        <>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div>{<div>{loadingMessage}</div>}</div>
        </>
      ) : (
        <Table className="custom-table" columns={columns} dataSource={data} />
      )}
    </div>
  );
};

export default ViewData;
